<script setup lang="ts">
import DisclaimerPop from "./DisclaimerPop.vue";

import { ref } from "vue";
const appName = ref<string>(APP_NAME);
const company = ref<string>(APP_CONFIG_COMPANY_NAME);
const icp = ref<string>(APP_CONFIG_BEI_AN_CODE);
</script>

<template>
  <div class="icp-wrapper">
    <span class="text">ICP备案/许可证号：</span>
    <a href="https://beian.miit.gov.cn/">{{ icp }}</a>
    <p class="text">{{ appName }}-{{ company }}</p>
    <DisclaimerPop :company="company"></DisclaimerPop>
  </div>
</template>

<style scoped lang="less">
.icp-wrapper {
  text-align: center;
  margin: 30px 0;
  font-size: 26px;
  line-height: 30px;

  a {
    text-decoration:underline;
  }
}
</style>
